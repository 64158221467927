<template>
  <div id="app" :style="{
    marginTop: adModal ? '100px' : '0',
    minHeight: adModal ? 'calc(100vh - 100px)' : '100vh'
  }">
    <div v-if="adModal" class="ad-modal" type="success">
      <div class="ad-modal-content">
        <img @click="adModal = false" width="25" height="25" class="close-x" src="/close-x.svg" alt="">
        <div class="ad-modal-center">
          <div class="ad-modal-logo">
            <img src="/logo-rahmat.svg" alt="Logo Rahmat">
          </div>
          <div class="ad-modal-texts">
            <h3>Rahmat APP</h3>
            <p>Оплата счёта, отзывы и чаевые</p>
            <div class="last-info">Все счета и фискальные онлайн чеки</div>
          </div>
        </div>
        <button @click="install" class="ad-modal-btn">Установить</button>
      </div>
    </div>
<!--    <div id="happy_new_year" style="overflow: hidden; position: relative; min-height: 100%">-->
      <router-view/>
<!--    </div>-->
  </div>
</template>
<script>

import {mapActions} from "vuex";

export default {
  data() {
    return {
      adModal: false,
      deferredPrompt: null,
    }
  },
  created() {
    if (this.$route.query.lang) {
      this.$i18n.locale = this.$route.query.lang
      this.setLanguage(this.$route.query.lang)
    }
    if (['MarketplaceDetail', 'CatalogPage'].some(page => this.$route.name?.includes(page))) {
      this.$store.commit('SET_BTN_COLOR', '#0faeae');
    }
  },
  beforeCreate() {
    const url = window.location.href
    if (url.includes('https://tips.multicard.uz')) {
      window.location.href = url.replace('https://tips.multicard.uz', 'https://app.rhmt.uz')
    }
  },
  mounted() {
    if (!this.$i18n.locale.trim().length) {
      this.$i18n.locale = 'ru'
      this.setLanguage('ru');
    }
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      if (['MenuPage', 'TakeawayPage', 'DeliveryPage', 'OrderPage', 'MenuAccountPage'].some(page => this.$route.name?.includes(page))) {
        this.adModal = false;
        this.updateAdModal(false);
      } else {
        this.adModal = true;
      }
      if (['DemoPage', 'MultikassaPage'].some(page => this.$route.name?.includes(page))) {
        this.adModal = false;
        this.updateAdModal(false);
      }
    });
    window.addEventListener('appinstalled', () => {
      this.adModal = false;
      this.deferredPrompt = null;
      console.log('PWA was installed');
    });
    // this.happyNewYear(); // see you to next New Year
  },
  methods: {
    ...mapActions(["updateAdModal", "setLanguage"]),
    async install() {
      this.adModal = false;
      this.deferredPrompt.prompt();
      const {outcome} = await this.deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      this.deferredPrompt = null;
    },
    happyNewYear() {
      const snowFall = () => {
        const bg = document.querySelector("#happy_new_year");
        for (let i = 0; i < 200; i++) {
          const snow = document.createElement("span");
          snow.classList.add("snow");
          if (i % 4 === 0) {
            snow.classList.add("snowAnimation1");
          } else if (i % 4 === 1) {
            snow.classList.add("snowAnimation2");
          } else if (i % 4 === 2) {
            snow.classList.add("snowAnimation3");
          } else {
            snow.classList.add("snowAnimation4");
          }
          const aNumber = -50 + randomNumber(150);
          const timeDelay = randomNumber(10);
          const animDuration = 5 + randomNumber(10) + Math.random() + 0.2;
          const snowOpacity = randomNumber(10) * 0.1;
          const heightWidth = randomNumber(11) + 5;
          snow.style.width = `${heightWidth}px`;
          snow.style.height = `${heightWidth}px`;
          snow.style.left = `${aNumber}%`;
          snow.style.animationDelay = `${timeDelay}s`;
          snow.style.animationDuration = `${animDuration}s`;
          snow.style.opacity = `${snowOpacity}`;
          bg?.appendChild(snow);
        }
      };

      const randomNumber = (limit) => {
        return Math.floor(Math.random() * limit);
      };

      snowFall();
    }
  },
  watch: {
    adModal(value) {
      this.updateAdModal(value)
    },
  }
}
</script>
<style scoped lang="scss">
.close-x {
  position: absolute;
  top: 11px;
  right: 9px;
}

.ad-modal {
  position: fixed;
  top: 0;
  background: #FFFFFF;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
  z-index: 15;
  max-width: 800px;
  width: 100%;

  :deep(.van-popup__close-icon--top-right) {
    top: 10px;
    right: 8px;
    font-size: 26px;
  }

  &-center {
    display: flex;
    gap: 8px;
  }

  &-content {
    display: flex;
    padding: 25px 16px;
    justify-content: space-between;
    position: relative;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
  }

  &-logo {
    width: 50px;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-texts {
    margin-left: 8px;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      margin: 0;
      padding: 0;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #000000;
      margin: 0;
      padding: 0;
    }

    div.last-info {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #6F6F6F;
      margin: 0;
      padding: 0;
    }
  }

  &-btn {
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 2px 8px;
    color: #000000;
    border: 1px solid #000000;
    border-radius: 8px;
    background-color: transparent;
    outline: none;
    margin-top: 20px;
    max-height: 20px;
  }
}
</style>
