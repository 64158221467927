<template>
  <div>
    <div class="label mt-30">{{ $t("name") }} <span>*</span></div>
    <input
      type="text"
      class="form-control"
      inputmode="text"
      :placeholder="$t('name')"
      v-model="nameModel"
      autocomplete="name"
    />
    <div class="label mt-20">{{ $t("phone") }}<span> *</span></div>
    <div class="input-group mb-20">
      <span class="input-group-addon" id="basic-addon1">+998</span>
      <the-mask
        v-model="phoneModel"
        :disabled="phoneDisabled"
        class="form-control"
        placeholder="00 000-00-00"
        mask="## ###-##-##"
        autocomplete="phone"
      />
    </div>
    <div class="text-center pt-20 pb-30">
      <van-button
        v-if="client === 'web'"
        @click="sendReview"
        :disabled="!validated"
        :loading="loadingCard === 'multicard'"
        :color="gradient"
        style="width: 200px"
      >{{ $t("leave-feedback") }}</van-button>
    </div>
  </div>
</template>

<script>
import {Button} from "vant"
import {TheMask} from "vue-the-mask"
import Service from "@/services/api/service";

export default {
  name: "ReviewBlock",
  props: {
    gradient: {
      type: String,
      default: 'linear-gradient(to left, #29BF9B, #0FAEAE)'
    },
    employee: {
      type: String,
      default: ""
    },
    comment: {
      type: String,
      default: ""
    },
    rating: {
      type: Number,
      default: 0
    },
    gif: {
      type: String,
      default: ""
    },
    audio: {
      type: String,
      default: ""
    },
    photo: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    phone: {
      type: String,
      default: ""
    },
    stickers: {
      type: Array,
      default: () => []
    },
    withoutBill: {
      type: Boolean,
      default: true
    }
  },
  components: {
    "van-button": Button,
    TheMask
  },
  data() {
    return {
      nameModel: '',
      phoneModel: '',
      validated: null,
      loadingCard: false,
      phoneDisabled: false
    }
  },
  methods: {
    async sendReview() {
      if (this.$route.name === 'ReviewPage') {
        await this.reviewStore()
      } else {
        await this.reviewPrecheck()
      }
    },
    async precheckButtonFunction() {
      await this.$store.dispatch("showLoading", true);
      const payload = {
        employee: this.employee,
        terminal_id: this.$route.params.store,
        user: {
          phone: "998" + this.phoneModel,
          name: this.nameModel,
        },
        rating: this.rating,
        comment: this.comment,
        tg: false,
        order_guid: null,
        order_details: null,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        stickers: this.stickers,
        gif: this.gif
      };
      await Service.payPrecheck(payload).then(() => {
        window.tg.close();
      }).finally(() => {
        this.$store.dispatch("showLoading", false);
      })
    },
    async reviewButtonFunction() {
      await this.$store.dispatch("showLoading", true);
      const payload = {
        terminal_id: this.$route.params.store,
        user: {
          phone: "998" + this.phoneModel,
          name: this.nameModel,
        },
        rating: this.rating,
        comment: this.comment,
        tg: true,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        gif: this.gif ? this.gif : null,
        stickers: this.stickers,
      };
      await Service.reviewStore(this.$route.params.store, this.$route.params.order, payload).then(() => {
        window.tg.close();
      }).finally(() => {
        this.$store.dispatch("showLoading", false);
      });
    },
    // hideReviewBtn() {
    //   if (this.client === "tg") {
    //     window.tg.MainButton.hide();
    //     window.tg.MainButton.disable();
    //   }
    // },
    enableReviewBtn() {
      if (this.client === "tg") {
        window.tg.MainButton.color = (this.gradient.substring(-1, 1) === '#') ? this.gradient : '#1fb7a4';
        window.tg.MainButton.enable();
      }
    },
    disableReviewBtn() {
      if (this.client === "tg") {
        window.tg.MainButton.color = "#93e0c6";
        window.tg.MainButton.disable();
      }
    },
    async reviewStore() {
      const payload = {
        terminal_id: this.$route.params.store,
        user: {
          phone: "998" + this.phoneModel,
          name: this.nameModel,
        },
        rating: this.rating,
        comment: this.comment,
        tg: false,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        gif: this.gif ? this.gif : null,
        stickers: this.stickers,
      }
      this.$emit('review', payload)
    },
    async reviewPrecheck() {
      this.loadingCard = 'multicard'
      const payload = {
        employee: this.employee,
        terminal_id: this.$route.params.store,
        user: {
          phone: "998" + this.phoneModel,
          name: this.nameModel,
        },
        rating: this.rating,
        comment: this.comment,
        tg: false,
        order_guid: null,
        order_details: null,
        audio: this.audio ? this.audio : null,
        image: this.photo ? this.photo : null,
        stickers: this.stickers,
        gif: this.gif
      };
      await Service.payPrecheck(payload).then(() => {
        this.$emit("success");
      }).catch(() => {
        // console.log(err);
      }).finally(() => {
        this.loadingCard = false;
      });
    }
  },
  created() {
    if (['ReviewPage', 'PreCheck'].some(page => this.$route.name?.includes(page))) {
      if (this.client === "tg") {
        window.tg = window.Telegram.WebApp;
        window.tg.expand();
        window.tg.MainButton.setParams({
          textColor: "#FFFFFF",
        });
      }
    }
  },
  mounted() {
    if (this.name) this.nameModel = this.name
    if (this.phone) this.phoneModel = this.phone.substring(3)
    if (['ReviewPage', 'PreCheck'].some(page => this.$route.name?.includes(page))) {
      if (window.tg?.MainButton) {
        window.tg.MainButton.setText(this.$t("leave-feedback"));
        window.tg.MainButton.show();
        if (this.$route.name === 'ReviewPage') {
          window.Telegram.WebApp.onEvent("mainButtonClicked", this.reviewButtonFunction);
        }
        if (this.$route.name === 'PreCheck') {
          window.Telegram.WebApp.onEvent("mainButtonClicked", this.precheckButtonFunction);
        }
      }
    }
  },
  computed: {
    client() {
      let client = "web";
      if (window.Telegram.WebApp.initData !== "") {
        client = "tg";
      }
      return client;
    },
  },
  watch: {
    gradient(value) {
      if (this.client === "tg" && window.tg.MainButton.isVisible && window.tg.MainButton.isActive) {
        window.tg.MainButton.color = (value.substring(-1, 1) === '#') ? this.gradient : '#1fb7a4';
      }
    },
    phoneModel(value) {
      this.validated = !!(value.length > 8 && this.nameModel)
    },
    nameModel(value) {
      this.validated = !!(value && this.phoneModel.length > 8)
    },
    validated(value) {
      if (value && this.withoutBill) {
        this.enableReviewBtn()
      } else {
        this.disableReviewBtn()
      }
    },
    withoutBill: {
      handler(value) {
        if (value && this.validated) {
          this.enableReviewBtn()
        } else {
          this.disableReviewBtn()
        }
      }
    }
  }
}
</script>

<style scoped></style>
